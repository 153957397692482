import { ref, watch, onMounted } from 'vue'

type Seo = {
  title?: string
  keywords?: string
  description?: string
  ogTitle?: string
  ogDescription?: string
  ogImage?: string
  twitterCard?: string
}

export const useNewkiSeo = (seo?: Seo) => {
  // 默认 SEO 数据
  const DefaultSEO: Seo = {
    title: '新奇站-每月家D新品未?!',
    keywords: '新奇站,NewkiLand,Newki,Yohome,JUJY,iLIVI,USATISFY,SKIDY,Comforbot,Double Clean,Dear.Min,FeelFing',
    description:
      '新奇站是一家提供全球創新奇品的線上線下仿家居生活應用場景的品牌體驗館，我們新奇品定位以生活最常見所需的品類為主，我們期望與品友一起成為新奇站的經營者，讓品友能充分參與決定新奇站發展的新商業理念。',
    ogImage: '',
    twitterCard: 'summary_large_image',
  }

  // 默認語言的SEO
  const cookieSeo: any = useCookie('newki-seo').value

  const title = seo?.title || cookieSeo?.siteTitle || DefaultSEO.title
  const keywords = seo?.keywords || cookieSeo?.keyword || DefaultSEO.keywords
  const description = seo?.description || cookieSeo?.siteDesc || DefaultSEO.description
  const ogTitle = seo?.ogTitle || cookieSeo?.siteTitle || DefaultSEO.title
  const ogDescription = seo?.ogDescription || cookieSeo?.siteDesc || DefaultSEO.description
  const ogImage = cookieSeo?.ogImage || DefaultSEO.ogImage
  const twitterCard = 'summary_large_image'

  const seoMeta: any = {
    title,
    keywords,
    description,
    ogTitle,
    ogDescription,
    ogImage,
    twitterCard,
  }

  // const seoMeta: any = {
  //   title: `${seo?.title ? seo?.title : cookieSeo?.siteTitle}`,
  //   keywords: `${seo?.keywords ? seo?.keywords : cookieSeo?.keyword}`,
  //   description: `${seo?.description ? seo?.description : cookieSeo?.siteDesc}`,
  //   ogTitle: `${seo?.ogTitle ? seo?.ogTitle : cookieSeo?.siteTitle}`,
  //   ogDescription: `${seo?.ogDescription ? seo?.ogDescription : cookieSeo?.siteDesc}`,
  //   ogImage: `${cookieSeo?.ogImage}`,
  //   twitterCard: 'summary_large_image',
  // }

  // console.log('seoMeta:', seoMeta)

  useSeoMeta(seoMeta)
}
